import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout>
    <SEO title="Privacy Policy" noindex />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0"></Col>
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">Privacy Policy</h1>
            <ShortLine />

            <p>
              <b></b>
              <strong>Effective Date:</strong> May 3rd, 2010
            </p>

            <p>
              <strong>Last Updated:</strong> April 27th, 2022
            </p>

            <p>
              We are (“us,” “we,” “our” or “Chicken Yard”) committed to respecting
              the privacy rights of visitors and users of this website (“Site”).
              We created this Privacy Policy (this “Policy”) to explain what
              information we collect from you, how we secure that information,
              and under what conditions we share your information with others.
              This Policy is only applicable to this Site, and not to any other
              website you may be able to access from this Site.
            </p>

            <h2>1. NOTICE CONCERNING CHILDREN.</h2>

            <p>
              Any website “directed at children under 13” must undertake certain
              compliance steps pursuant to the Children’s Online Privacy
              Protection Act [15 U.S. Code § 6502] and under age 16 pursuant to
              Article 8 of the General Data Protection Regulation. This Site is
              directed at adults, not children. Please contact us immediately if
              you have knowledge of a child under 16 using the Site.
            </p>

            <h2>2. PERSONAL INFORMATION COLLECTED.</h2>

            <p>
              <strong>A.</strong> Information Collection: In order for you to
              access the Site, we require you to provide us with certain
              information that personally identifies you (&#8220;Personal
              Information&#8221;). Personal Information includes the following
              categories of information: (1) Contact Data such as your e-mail
              address. We also collect and retain any information you provide us
              when you contact us. If you purchase a product or service through
              the Site from another party, you will be doing so through a third
              party website and should refer to the privacy policy of that
              website.
            </p>

            <p>
              <strong>B.</strong> Modification or Removal Process: You may
              opt-out of our email newsletter by selecting the “unsubscribe”
              link in any message you receive.
            </p>

            <p>
              <strong>C.</strong> Privacy Policy Updates: If we modify or update
              this Policy, we will notify you by posting a notice on the Site
              and by sending you an electronic message indicating as much if we
              have an email address for you. You may reject the update by
              discontinuing your use of the Site.
            </p>

            <p>
              <strong>D.</strong> Do Not Track Signals: We do not comply with
              “do not track” signals at this time.
            </p>

            <p>
              <strong>E.</strong> Google Tracking: We use certain Google
              programs to help us analyze the traffic to this Site and improve
              our marketing efforts. Such programs may include, but are not
              limited to, Google Analytics Remarketing, Google Display Network
              Impression Reporting, Google Analytics Demographics and Interest
              Reporting, and Integrated services that require Google Analytics
              to collect data via advertising cookies and identifiers. You may
              opt out of this tracking by visiting
              https://tools.google.com/dlpage/gaoptout/ and{" "}
              <u>https://www.networkadvertising.org/choices/</u>.
            </p>
            <p>
              <strong>F.</strong> Third Party Advertising Cookies: We allow
              third party vendors, including Google Adsense, to use cookies to
              serve ads based on your prior visits to this Site or other
              websites. These companies use of advertising cookies enables them
              and their partners to serve ads to you based on your visit to
              sites on the Internet. We specifically employ Google Adsense on
              this site, and Google will use a Double Click cookie to track your
              actions in this regard. You can opt out of this program by
              visiting https://www.google.com/settings/ads or by other third
              parties by visiting https://www.aboutads.info/choices/.
            </p>
            <h2>3. GENERAL TRAFFIC DATA COLLECTED.</h2>
            <p>
              We automatically track and collect the following categories of
              information when you visit our Site: (1) IP addresses; (2) domain
              servers; (3) types of computers accessing the Site; and (4) types
              of web browsers used to access the Site (collectively
              &#8220;Traffic Data&#8221;). Traffic Data is anonymous information
              that does not personally identify you but is helpful for marketing
              purposes and for helping us improve your experience when using the
              Site.
            </p>

            <h2>4. USE OF INFORMATION COLLECTED</h2>

            <p>
              <strong>A.</strong> Our Use Of Information: We use your Personal
              Information to interact with you and evaluate our Site as well as
              comply with various laws as detailed herein. Please read this
              Section 4 carefully.
            </p>

            <p>
              <strong>B.</strong> Sharing Personal Information: We share certain
              categories of information we collect from you in the ways
              described in this Policy. We share Contact Data with our business
              partners who assist us by performing core services (email
              newsletter functionality, etc.) related to our operation of the
              Site. If you do not want us to share your Personal Information
              with any third parties, please contact us, but understand that
              such a request will eliminate your ability to receive newsletters
              from us.
            </p>

            <p>
              <strong>C.</strong> Use of Contact Data. We use your Contact Data
              to provide you with administrative communications such as
              responses to messages you send us. We also use your Contact Data
              to send you commercial emails highlighting products, services, and
              business developments. These email communications may contain
              messages, advertisements, and affiliate links promoting third
              parties and third party products. Make sure to review the privacy
              policies of these third party companies before providing them with
              your personal information.
            </p>

            <p>
              <strong>D.</strong> Sale of Information: Should we decide to sell
              all or part of our business or this Site at some point in the
              future, your Personal Information will be included as an asset in
              the transfer of the business to the new owner.
            </p>

            <h2>5. INFORMATION SECURITY</h2>

            <p>
              <strong>A.</strong> Confidentiality and Security of Personal
              Information: Except as otherwise provided in this Policy, we will
              keep your Personal Information private and will not share it with
              third parties, unless such disclosure is necessary to: (a) comply
              with a court order or other legal process; (b) protect our rights
              or property; or (c) enforce the legal documents applicable to the
              Site.
            </p>

            <p>
              <strong>B.</strong> Site Security. The Site has reasonable
              security measures in place to prevent the loss, misuse, or
              alteration of the information that we obtain from you.
              Unfortunately, we make no promises about our ability to prevent
              any such loss or misuse of your Personal Information since
              preventing hacking is nearly impossible.
            </p>

            <h2>6. AREAS BEYOND OUR CONTROL</h2>

            <p>
              <strong>A.</strong> Public Information: We may allow you to
              interact with others on the Site such as in comments on blog
              posts. Please understand that any information disclosed in these
              areas becomes public information. We have no control over its use,
              and you should exercise caution when deciding to disclose your
              Personal Information.
            </p>
            <p>
              <strong>B.</strong> Third Party Websites: The Site contains links
              to other websites. We have no control over the terms and privacy
              policies of said sites. Please make sure to view the policies of
              those sites before proceeding.
            </p>

            <h2>7. GDPR COMPLIANCE</h2>

            <p>
              A. DATA USAGE
              <br />
              In accordance with the EU General Data Protection Regulation
              (Regulation (EU) 2016/679) (GDPR) We use your data for the
              following:
            </p>

            <ol>
              <li>Managing your access to Our Site;</li>
              <li>Personalising your experience on Our Site;</li>
              <li>
                Personalising and tailoring Our products and services for you;
              </li>
              <li>Market research;</li>
              <li>Communicating with you directly;</li>
              <li>
                Sending out promotional emails or emails related to your account
                that you have subscribed to (you may unsubscribe at any time by
                clicking ‘Unsubscribe’ at the bottom of any email We send you);
              </li>
              <li>Analysing your use of Our Site;</li>
            </ol>
            <p>
              With your permission and/or where permitted by law, We may use
              your data for marketing purposes which may include contacting you
              by email with information, news and offers on Our products AND
              services. We will endeavour to ensure that We fully protect your
              rights and comply with Our obligations under the GDPR and the
              Privacy and Electronic Communications (EC Directive) Regulations
              2003, as amended in 2004, 2011 and 2015.
            </p>
            <p>
              Under GDPR we will ensure that your personal data is processed
              lawfully, fairly, and transparently, without adversely affecting
              your rights. We will only process your personal data if at least
              one of the following applies:
            </p>
            <ol>
              <li>
                You have given consent to the processing of your personal data;
              </li>
              <li>
                Processing is necessary for the performance of a contract to
                which you are a party or in order to take steps at the request
                of you prior to entering into a contract;
              </li>
              <li>
                Processing is necessary for compliance with a legal obligation
                to which We are subject;
              </li>
              <li>
                Processing is necessary to protect the vital interests of you or
                of another natural person;
              </li>
              <li>
                Processing is necessary for the performance of a task carried
                out in the public interest or in the exercise of official
                authority vested in the controller; and/or
              </li>
              <li>
                Processing is necessary for the purposes of the legitimate
                interests pursued by us or by a third party, except where such
                interests are overridden by the fundamental rights and freedoms
                of the data subject which require protection of personal data,
                in particular where the data subject is a child.
              </li>
            </ol>
            <p>
              B. DATA STORAGE: WHEN AND WHERE
              <br />
              We only keep your data for as long as We need to in order to use
              it as described above, and/or for as long as We have your
              permission to keep it. Your data will be deleted if We no longer
              need it in accordance with the terms of our Data Retention Policy.
            </p>
            <p>
              Our Data Retention Policy is to keep data only as long as is
              necessary to provide a service or to you, or as long as is
              required by law. Should data no longer be required, it is also
              within our Data Retention Policy to remove data from our systems
              in a secure and timely manner.
            </p>
            <p>
              For European visitors, some or all of your data may be stored or
              transferred outside of the European Economic Area (“the EEA”). You
              are deemed to accept and agree to this by using Our Site and
              submitting information to Us. We will take all reasonable steps to
              ensure that your data is treated as safely and securely as it
              would be within the EEA and under the GDPR.
            </p>
            <p>
              Data security is of great importance to Us, and to protect your
              data We have put in place suitable physical, electronic and
              managerial procedures to safeguard and secure data collected
              through Our Site.
            </p>
            <p>
              We will only work with third-party companies who adhere to the EU
              GDPR and We will endeavour to keep up to date with new legislation
              to ensure We are securing and protecting your data in line with
              international regulation.
            </p>
            <p>
              C. DATA SHARING
              <br />
              We may share your data with other companies.
            </p>
            <p>
              We may contract with third parties to supply products and services
              to you on Our behalf. These may include payment processors,
              delivery of goods, search engine facilities, advertising and
              marketing. In some cases, the third parties may require access to
              some or all of your data. Where any of your data is required for
              such a purpose, We will take all reasonable steps to ensure that
              your data will be handled safely, securely, and in accordance with
              your rights, Our obligations, and the obligations of the third
              party under the law.
            </p>
            <p>
              We currently share this data <strong>Drip</strong> (LeadPages),{" "}
              <strong>ClickBank</strong>, <strong>Google</strong> and{" "}
              <strong>Facebook</strong>.
            </p>
            <p>
              We may compile statistics about the use of Our Site including data
              on traffic, usage patterns, user numbers, sales and other
              information. All such data will be anonymised and will not include
              any personally identifying information. We may from time to time
              share such data with third parties such as prospective investors,
              affiliates, partners and advertisers. Data will only be shared and
              used within the bounds of the law.
            </p>
            <p>
              In certain circumstances We may be legally required to share
              certain data held by Us, which may include your personal
              information, for example, where We are involved in legal
              proceedings, where We are complying with the requirements of
              legislation, a court order, or a governmental authority. We do not
              require any further consent from you in order to share your data
              in such circumstances and will comply as required with any legally
              binding request that is made of Us.
            </p>
            <p>
              D. DATA CONTROL
              <br />
              When you submit information via Our Site, you may be given options
              to restrict Our use of your data. We aim to give you strong
              controls on Our use of your data (including the ability to opt-out
              of receiving emails from Us which you may do by unsubscribing
              using the links provided in Our emails and at the point of
              providing your details).
            </p>
            <p>
              At any time, you may also request in writing changes or complete
              removal of all your data by contacting us here.
            </p>
            <p>
              You may access certain areas of Our Site without providing any
              data at all. However, to use all features and functions available
              on Our Site you are required to submit or allow for the collection
              of certain data.
            </p>
            <p>You may restrict your Internet browser’s use of Cookies.</p>
            <p>
              You may withdraw your consent for Us to use your personal data as
              set out in this Privacy Policy at any time by contacting Us using
              the details set out below, and We will delete Your data from Our
              system.
            </p>
            <p>
              E. DATA ACCESS
              <br />
              You have the legal right to request a copy of any of your personal
              data held by Us (where such data is held) by contacting us here.
            </p>
            <p>F. COOKIES</p>
            <p>
              All Cookies used by and on Our Site are used in accordance with EU
              Cookie Law.
            </p>
            <p>
              Before any Cookies are placed on your computer or device, you will
              be shown a message bar requesting your consent to set those
              Cookies. By continuing to use our site, you are giving your
              consent to Our cookies being used. You may, if you wish, deny
              consent to the placing of Cookies by restricting their use in your
              Internet browser settings.
            </p>
            <p>
              G. SUMMARY OF YOUR GDPR RIGHTS
              <br />
              Under the GDPR, you have:
            </p>
            <ol>
              <li>
                The right to request access to, deletion of or correction of,
                your personal data held by Us;
              </li>
              <li>The right to complain to a supervisory authority;</li>
              <li>Be informed of what data processing is taking place;</li>
              <li>The right to restrict processing;</li>
              <li>The right to data portability;</li>
              <li>Object to processing of your personal data;</li>
              <li>
                Rights with respect to automated decision-making and profiling.
              </li>
            </ol>
            <p>8. CONTACT INFORMATION</p>
            <p>
              If you have any questions about this Policy or our practices
              related to this Site, please{" "}
              <Link to="/contact/">contact us</Link>.
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
`
